import React from "react";
import classes from "./Layout.module.scss";
import {Link, matchPath, useLocation} from "react-router-dom";
import {Paper} from "@mui/material";
import {ReactComponent as BestelLuchtfotoLogo} from "./BestelLuchtfotoLogo.svg";

const Layout: React.FC = (props) => {

  const location = useLocation();
  const onMapPage = matchPath(location.pathname, '/step2');

  return (
    <div className={classes.Layout}>
      <Paper className={classes.wrapper} elevation={3} square>
        <div className={classes.container}>
          <div className={[classes.Logo, onMapPage && classes.LogoHideLowScreen].join(' ')}>
            <BestelLuchtfotoLogo />
            <div className="sr-only">BestelLuchtfoto.nl</div>
          </div>
          {props.children}
        </div>

        <div className={classes.Footer}>
          <div className={classes.right}>
            <nav role="navigation" aria-label="Hoofdmenu">
              <ul>
                <li><Link to="/" title="Ga naar de homepagina">Home</Link></li>
                <li><Link to="/informatie" title="Meer informatie over de luchtfoto's en deze dienst">Informatie</Link></li>
                <li><Link to="/algemenevoorwaarden" title="Lees de algemene voorwaarden">Voorwaarden</Link></li>
                <li><Link to="/privacy" title="Lees de privacy statement">Privacy</Link></li>
                <li><a href="https://www.bestel3d.nl/form/contact" title="Neem contact met ons op.">Contact</a></li>
              </ul>
            </nav>
          </div>
        </div>
      </Paper>
    </div>
  );
}

export default Layout;
