import Grid from "@mui/material/Grid";
import {Autocomplete, Button, CircularProgress, TextField} from "@mui/material";
import {LookupResult, SearchLocation, SearchResult, SearchResultDoc} from "../../types/PdokSearchResults";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import {NavigateNext} from "@mui/icons-material";
import React, {useEffect, useRef, useState} from "react";
import makeStyles from "@mui/styles/makeStyles";
import api, {CancelToken, isCancel} from "../../store/api";

const useStyles = makeStyles((theme) => ({
  searchBox: {
    flexGrow: 1,
  },
  input: {
    height: 56,
  },
  icon: {
    color: theme.palette.text.secondary,
    marginRight: theme.spacing(2),
  },
  button: {
    fontSize: 25,
    '& .MuiButton-endIcon': {
      margin: 0
    },
    [theme.breakpoints.up('md')]: {
      fontSize: 'inherit',
    },
  },
  buttonWrapper: {
    position: 'relative',
    margin: theme.spacing(1),
  },
  buttonText: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'block',
    },
  }
}));

interface IProps {
  onSubmit: (location: SearchLocation) => void
}

const emptySearchResult = {
  response: {
    numFound: 0,
    start: 0,
    maxScore: 0,
    docs: []
  },
  highlighting: {}
};

const SearchForm: React.FC<IProps> = (props) => {
  const classes = useStyles();

  const [requestCancelToken, setRequestCancelToken] = useState(CancelToken.source());
  const createNewCancelToken = () => {
    requestCancelToken.cancel();
    const token = CancelToken.source();
    setRequestCancelToken(token);
    return token.token;
  }

  const [location, setLocation] = useState<SearchLocation | null>(null);
  const [suggestLoading, setSuggestLoading] = useState(false);
  const [lookupLoading, setLookupLoading] = useState(false);

  const [inputValue, setInputValue] = useState('');
  const [value, setValue] = useState<SearchResultDoc | null>(null);
  const [searchResults, setSearchResults] = useState<SearchResult>(emptySearchResult);
  const [loadingError, setLoadingError] = useState(false);

  const textInputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    setSearchResults(emptySearchResult);
    setLoadingError(false);
    if (inputValue === '') {
      setSuggestLoading(false);
      return;
    }
    setSuggestLoading(true);

    const timer = setTimeout(() => {
      if (textInputRef.current!.value === inputValue) {
        const url = `/geolocate/suggest/${encodeURI(inputValue)}`;
        api.get<SearchResult>(url, {cancelToken: createNewCancelToken()})
          .then(response => {
            setSearchResults(response.data);
          })
          .catch((error) => {
            if (isCancel(error)) {
              return;
            }
            setLoadingError(true);
          })
          .finally(() => {
            setSuggestLoading(false);
          });
      }
    }, 500);
    return () => {
      requestCancelToken.cancel();
      clearTimeout(timer);
    }
    // eslint-disable-next-line
  }, [inputValue, textInputRef]);

  useEffect(() => {
    if (!value) {
      return;
    }
    setLookupLoading(true);
    const url = `/geolocate/lookup/${value.id}`;
    api.get<LookupResult>(url, {cancelToken: createNewCancelToken()})
      .then(response => {
        try {
          setLocation(new SearchLocation(response.data.response.docs[0]));
        } catch (error) {
          if (isCancel(error)) {
            return;
          }
          console.error('RESPONSE LOCATION ERROR', error);
        }
      })
      .finally(() => {
        setLookupLoading(false);
      });
    return () => requestCancelToken.cancel();
    // eslint-disable-next-line
  }, [value, setLookupLoading, setLocation]);

  const onSubmitHandler = (e: React.FormEvent) => {
    e.preventDefault();
    if (location && !lookupLoading) {
      requestCancelToken.cancel();
      props.onSubmit(location);
    }
  }

  return (
    <form onSubmit={onSubmitHandler}>
      <Grid container alignItems="center" justifyContent="space-between">
        <Grid item className={classes.searchBox}>

          <Autocomplete
            options={searchResults.response.docs}
            getOptionLabel={(option) => option.weergavenaam}
            value={value}
            filterOptions={x => x}
            loadingText="Bezig met laden..."
            noOptionsText={loadingError ? "Er is iets mis gegaan." : (inputValue === "" ? "Begin met zoeken..." : "Geen locaties gevonden.")}
            clearText="Legen"
            loading={suggestLoading}
            clearOnBlur={false}
            autoHighlight
            fullWidth
            onChange={(e: any, newValue: SearchResultDoc | null) => {
              setValue(newValue);
            }}
            inputValue={inputValue}
            onInputChange={(e, newInputValue) => {
              if (location) {
                setLocation(null);
              }
              setInputValue(newInputValue);
            }}
            isOptionEqualToValue={((option, value) => option.id === value.id)}
            renderInput={(params) => (
              <TextField
                {...params}
                className={classes.input}
                inputRef={textInputRef}
                label="Voer een plaats of adres in en kies uit de lijst."
                variant="outlined"
              />
            )}
            renderOption={(props, option) => (
              <li {...props}>
                <Grid container alignItems="center">
                  <Grid item>
                    <LocationOnIcon className={classes.icon}/>
                  </Grid>
                  <Grid item xs>
                    {option.weergavenaam}
                  </Grid>
                </Grid>
              </li>
            )}
          />

        </Grid>

        <Grid item>
          <div className={classes.buttonWrapper}>
            <Button
              className={[classes.input, classes.button].join(' ')}
              variant="contained"
              color="primary"
              endIcon={
                lookupLoading ? <CircularProgress size={20} />
                  : <NavigateNext/>
              }
              type="submit"
              disabled={!location || lookupLoading}
            >
              <span className={classes.buttonText}>
                Ga verder
              </span>
            </Button>

          </div>
        </Grid>
      </Grid>
    </form>
  );

}

export default SearchForm;
