import React, {Fragment, useContext, useEffect, useState} from "react";
import { useNavigate } from "react-router-dom";
import WizardContext from "../../store/wizard-context";

interface propsInterface {
  step: number,
}

const StepChecker: React.FC<propsInterface> = (props) => {
  const wizardContext = useContext(WizardContext);
  const navigate = useNavigate();
  const [valid, setValid] = useState(false);

  useEffect(() => {
    let maxStep = 1;
    let valid = true;

    // Check step 2.
    if (valid && wizardContext.searchLocation) {
      maxStep = 2;
    }
    else {
      valid = false;
    }

    if (valid && wizardContext.boundingBox) {
      maxStep = 3;
    }
    else {
      valid = false;
    }

    if (valid && wizardContext.downloadMode) {
      maxStep = 4;
    }

    if (props.step > maxStep) {
      navigate(`/step${maxStep}`)
    }
    else {
      setValid(true);
    }
  }, [navigate, props.step, wizardContext.boundingBox, wizardContext.downloadMode, wizardContext.searchLocation]);

  return (
    <Fragment>
      {valid ? props.children : null}
    </Fragment>
  );
}

export default StepChecker;
