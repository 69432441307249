import React from "react";
import classes from "./GratisInfopagina.module.scss";
import lowResImageKlein from "./Voorbeeld_LuFo_LowRes-klein.jpg";
import lowResImageGroot from "./Voorbeeld_LuFo_Groningen_50cm_2021.jpg";
import highResImageKlein from "./Voorbeeld_LuFo_HiRes-klein.jpg";
import highResImageGroot from "./Voorbeeld_LuFo_Groningen_7_5cm_2021.jpg";
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
  stepContainer: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  }
}));
const GratisInfoPagina = () => {
  const containerClasses = useStyles();
  return (
    <div className={containerClasses.stepContainer}>
      <h1>Algemene informatie</h1>

      <p>Gratis luchtfoto's worden ter beschikking gesteld door de Nederlandse overheid en zijn prima geschikt om een
        eerste indruk te krijgen van een bepaald gebied. De beelden hebben een lage resolutie en de detaillering is
        beperkt.</p>

      <p>Het is ook mogelijk om hoge resolutie luchtfoto's te kopen voor elk deel van Nederland vanaf € 5,00 excl. BTW.
        Deze luchtfoto's hebben een 40 maal hogere resolutie (kwaliteit) en daardoor een bredere toepasbaarheid.</p>

      <p>Hieronder is een vergelijking gemaakt van een gratis variant (links)en de betaalde variant (rechts). Het
        verschil in resolutie is duidelijk zichtbaar.</p>

      <div className={classes.comparison}>
        <table>
          <thead>
          <tr>
            <th/>
            <th>Lage resolutie luchtfoto</th>
            <th>Hoge resolutie luchtfoto</th>
          </tr>
          </thead>
          <tbody>
          <tr>
            <th>Resolutie</th>
            <td>Beperkt</td>
            <td>Hoog (8cm/pixel)</td>
          </tr>
          <tr>
            <th>Georeferentie</th>
            <td>Nee</td>
            <td>Ja</td>
          </tr>
          <tr>
            <th>Toepassing</th>
            <td>Beperkt</td>
            <td>Commercieel, Brochures, <br/>Rapporten, Advertenties</td>
          </tr>
          </tbody>
        </table>

        <a href={lowResImageGroot} title="Bekijk Gratis-Luchtfoto" target="_blank" rel="noopener noreferrer">
          <img src={lowResImageKlein} alt="Gratis luchtfoto met lage resolutie"/>
        </a>&nbsp;
        <a href={highResImageGroot} title="Bekijk high-res luchtfoto" target="_blank" rel="noopener noreferrer">
          <img src={highResImageKlein} alt="BestelLuchtfoto met hoge resolutie"/>
        </a>
      </div>
    </div>
  );
}

export default GratisInfoPagina;
