import axios, {AxiosError, AxiosRequestConfig, AxiosResponse} from "axios";

export const API_URL = process.env.REACT_APP_API_URL;
axios.defaults.baseURL = API_URL;
axios.defaults.xsrfCookieName = 'XSRF-TOKEN';
axios.defaults.withCredentials = true;

const onRequest = (config: AxiosRequestConfig): AxiosRequestConfig => {
  const token = localStorage.getItem('token');
  config.headers['X-CSRF-TOKEN'] = token;
  return config;
}

const onRequestError = (error: AxiosError): Promise<AxiosError> => Promise.reject(error);

const onResponse = (response: AxiosResponse): AxiosResponse => response;

const onResponseError = async (error: AxiosError): Promise<AxiosError> => {
  if (error.response) {
    if (error.response.status === 419) {
      try {
        const response = await axios.get<string>('/token');
        localStorage.setItem('token', response.data);
        error.config.headers['X-CSRF-TOKEN'] = response.data;
        return axios.request(error.config);
      }
      catch (_error) {
        return Promise.reject(_error);
      }
    }
  }

  return Promise.reject(error);
}

const api = axios.create();
api.interceptors.request.use(onRequest, onRequestError);
api.interceptors.response.use(onResponse, onResponseError);

export const CancelToken = axios.CancelToken;
export const isCancel = axios.isCancel;
export default api;
