import classes from "./Cross.module.scss"

const Cross = () => {
  return (
    <svg
      className={classes.cross}
      xmlns={"http://www.w3.org/2000/svg"}
      style={{width: '128px', height: '128px'}}
      viewBox="0 0 52 52">
      <circle className={classes.cross__circle} cx="26" cy="26" r="25" fill="none" />
      <path className={classes.cross__cross} fill="none" d="M 39.090799,10 25.9997,23.091099 12.9089,10.0003 10,12.909199 l 13.090799,13.0908 -13.090797,13.0908 2.909201,2.9092 L 26,28.909199 39.090799,42 41.999698,39.0911 28.908898,26.0003 42,12.9092 Z" />
    </svg>
  )
}

export default Cross;
