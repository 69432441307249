import React from "react";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

interface IProps {
  title: string;
  open: boolean;
  fullscreen?: boolean;
  onClose: () => void;
  onAccept: () => void;
}

const EulaAcceptDialog: React.FC<IProps> = (props) => {
  return (
    <Dialog
      open={props.open}
      scroll="paper"
      fullScreen={props.fullscreen || false}
    >
      <DialogTitle id="eula-diaog-title">
        {props.title}
        <IconButton
          aria-label="sluiten"
          onClick={props.onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon/>
        </IconButton>
      </DialogTitle>
      <DialogContent dividers sx={{
        fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
        fontWeight: 400,
        color: "rgba(0, 0, 0, 0.6)"
      }}>
        {props.children}
      </DialogContent>
      <DialogActions>
        <Button onClick={props.onAccept}>Accepteren</Button>
      </DialogActions>
    </Dialog>
  );
}

export default EulaAcceptDialog;
