import React, {useContext, useState} from "react";
import Grid from "@mui/material/Grid";
import {Box, CircularProgress, Dialog, Fab, useMediaQuery, useTheme} from "@mui/material";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import {SideBySideMagnifier} from "react-image-magnifiers";
import makeStyles from "@mui/styles/makeStyles";
import WizardContext from "../../store/wizard-context";
import {useNavigate} from "react-router-dom";
import {API_URL} from "../../store/api";

const useStyles = makeStyles((theme) => ({
  stepContainer: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  }
}));

const Step3: React.FC = () => {
  const wizardContext = useContext(WizardContext);
  const navigate = useNavigate();

  const [fullExampleOpen, setFullExampleOpen] = useState(false);
  const [fullExampleLoading, setFullExampleLoading] = useState(false);
  const [freeExampleOpen, setFreeExampleOpen] = useState(false);
  const [freeExampleLoading, setFreeExampleLoading] = useState(false);

  const classes = useStyles();
  const theme = useTheme();

  const dialogFullscreen = useMediaQuery(theme.breakpoints.down('md'));

  const boundingBox = wizardContext.boundingBox!;

  const realWorldWidth = boundingBox[2] - boundingBox[0];

  const maxResolution = 8;
  const imageWidth = Math.min((boundingBox[2] - boundingBox[0]) / (maxResolution / 100), 4000);
  const imageHeight = Math.min((boundingBox[3] - boundingBox[1]) / (maxResolution / 100), 4000);

  const freeResolution = Math.round(realWorldWidth / 600 * 100);
  const resolution = Math.round(realWorldWidth / imageWidth * 100 * 10) / 10;
  const isMaxResolution = resolution === maxResolution;

  const fullExampleUrl = API_URL + '/lufo_example/' + boundingBox.join(',') + '/1?example=1&_token=' + localStorage.getItem('token');
  const freeExampleUrl = API_URL + '/lufo_example/' + boundingBox.join(',') + '/0?example=1&_token=' + localStorage.getItem('token');

  const preLoadExample = (url: string, loadedHandler: () => void, errorHandler: () => void) => {
    const imageLoader = new Image();
    imageLoader.src = url;

    imageLoader!.onload = () => {
      loadedHandler();
    }

    imageLoader.onerror = () => {
      errorHandler();
    }
  }

  const handleClickFullExample = () => {
    setFullExampleLoading(true);
    preLoadExample(fullExampleUrl, () => {
        setFullExampleLoading(false);
        setFullExampleOpen(true);
      },
      () => {
        setFullExampleLoading(false);
      });
  }

  const handleClickFreeExample = () => {
    setFreeExampleLoading(true);
    preLoadExample(freeExampleUrl, () => {
        setFreeExampleLoading(false);
        setFreeExampleOpen(true);
      },
      () => {
        setFreeExampleLoading(false);
      });
  }

  const handleExampleClose = () => {
    setFullExampleOpen(false);
    setFreeExampleOpen(false);
  }

  const fullDownloadClickHandler = () => {
    wizardContext.downloadMode = "full";
    navigate('/step4');

  }

  const freeDownloadClickHandler = () => {
    wizardContext.downloadMode = "free";
    navigate('/step4');
  }

  return (
    <div className={classes.stepContainer}>
      <Grid container alignItems="center" justifyContent="space-between">
        <Grid item md={7}>
          <h2>Hoge kwaliteit</h2>
          <ul>
            <li>Prijs voor deze luchtfoto: €5,-</li>
            <li>{isMaxResolution ? "Hoogste resolutie" : "Hogere resolutie"}: {resolution}cm/pixel</li>
            <li>Grootst mogelijke formaat: {imageWidth}x{imageHeight} pixels</li>
            <li>Geen watermerk over luchtfoto</li>
          </ul>

          <Box sx={{display: 'flex', alignItems: 'center'}}>
            <Box sx={{position: 'relative', mr: 1}}>
              <Fab
                variant="extended"
                color="secondary"
                disabled={fullExampleLoading || freeExampleLoading || fullExampleOpen || freeExampleOpen}
                onClick={handleClickFullExample}>
                Voorbeeld
                <NavigateNextIcon/>
              </Fab>
              {fullExampleLoading && (
                <CircularProgress
                  size={24}
                  sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    marginTop: '-12px',
                    marginLeft: '-12px'
                  }}
                />
              )}
            </Box>
            <Fab variant="extended" color="primary" onClick={fullDownloadClickHandler}>
              Download nu
              <NavigateNextIcon/>
            </Fab>
          </Box>
        </Grid>

        <Grid item md={5}>

          <h2>Lage kwaliteit</h2>
          <ul>
            <li>Gratis</li>
            <li>Lagere resolutie: {freeResolution}cm/pixel</li>
            <li>Kleiner formaat: 600x600 pixels</li>
            <li>Watermerk over luchtfoto</li>
          </ul>

          <Box sx={{display: 'flex', alignItems: 'center'}}>
            <Box sx={{position: 'relative', mr: 1}}>
              <Fab
                variant="extended"
                color="secondary"
                disabled={fullExampleLoading || freeExampleLoading || fullExampleOpen || freeExampleOpen}
                onClick={handleClickFreeExample}>
                Voorbeeld
                <NavigateNextIcon/>
              </Fab>
              {freeExampleLoading && (
                <CircularProgress
                  size={24}
                  sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    marginTop: '-12px',
                    marginLeft: '-12px'
                  }}
                />
              )}
            </Box>
            <Fab variant="extended" color="primary" onClick={freeDownloadClickHandler}>
              Download nu
              <NavigateNextIcon/>
            </Fab>
          </Box>
        </Grid>
      </Grid>

      <Dialog
        fullScreen={dialogFullscreen}
        open={fullExampleOpen && !freeExampleOpen}
        onClose={handleExampleClose}
      >
        <SideBySideMagnifier
          imageSrc={fullExampleUrl}
          alwaysInPlace={true}
        />
      </Dialog>
      <Dialog
        fullScreen={dialogFullscreen}
        open={freeExampleOpen && !fullExampleOpen}
        onClose={handleExampleClose}
      >
        <SideBySideMagnifier
          imageSrc={freeExampleUrl}
          alwaysInPlace={true}
        />
      </Dialog>

    </div>
  );
}

export default Step3;
