import React from "react";
import PrivacyStatementText from "../../components/PrivacyStatementText/PrivacyStatementText";
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
  stepContainer: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  }
}));

const PrivacyStatement = () => {
  const classes = useStyles();
  return (
    <div className={classes.stepContainer}>
      <h1>Privacyreglement</h1>

      <PrivacyStatementText />
    </div>
  );
}

export default PrivacyStatement;
