import React from "react";
import AlgemeneVoorwaardenText from "../../components/AlgemeneVoorwaardenText/AlgemeneVoorwaardenText";
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
  stepContainer: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  }
}));

const AlgemeneVoorwaarden = () => {
  const classes = useStyles();
  return (
    <div className={classes.stepContainer}>
      <h1>Algemene voorwaarden</h1>

      <p>Op deze website gelden de volgende algemene voorwaarden:</p>

      <AlgemeneVoorwaardenText />
    </div>
  );
}

export default AlgemeneVoorwaarden;
