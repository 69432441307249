export interface SearchResult {
  response: {
    numFound: number
    start: number
    maxScore: number
    docs: SearchResultDoc[]
  }
  highlighting: {
    [key: string]: {
      suggest: string[]
    }
  }
}

export interface SearchResultDoc {
  type: string
  weergavenaam: string
  id: string
  score: number
}

export interface LookupResult {
  response: {
    numFound: number,
    start: number,
    maxScore: number,
    docs: LookupResultDoc[]
  }
}

export interface LookupResultDoc {
  weergavenaam: string,
  centroide_rd: string,
  type: string
}

export class SearchLocation {
  public weergavenaam: string;
  public coords: [number, number];
  public type: string;

  constructor(location: LookupResultDoc) {
    this.weergavenaam = location.weergavenaam;
    this.type = location.type;

    const match = location.centroide_rd.match(/POINT\((\d+(?:\.\d+)?) (\d+(?:\.\d+)?)\)/);
    if (match) {
      this.coords = [parseFloat(match[1]), parseFloat(match[2])];
    }
    else {
      throw new Error(`No location found for ${location.centroide_rd}`);
    }
  }

  public get zoomLevel() {
    switch (this.type) {
      case 'appartementsrechts':
      case 'hectometerpaal':
      case 'perceel':
      case 'adres':
      case 'postcode':
      case 'weg':
        return 11;

      case 'wijk':
      case 'buurt':
        return 10;

      case 'woonplaats':
        return 8;

      case 'gemeente':
        return 6;

      default:
        return 4;
    }
  }
}

export interface StartLocation {
  center: [number, number],
  zoom: number,
}
