import React, {useContext, useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {CircularProgress} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import api from "../../store/api";
import {LookupResult, SearchLocation} from "../../types/PdokSearchResults";
import WizardContext from "../../store/wizard-context";

const useStyles = makeStyles((theme) => ({
  stepContainer: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    textAlign: "center"
  }
}));

const Start: React.FC = () => {
  const [error, setError] = useState(false);
  const {startParams, locationId} = useParams();
  const wizardContext = useContext(WizardContext);
  const navigate = useNavigate();
  const classes = useStyles();

  useEffect(() => {
    const regex = /^\d{4,6},\d{4,6},\d{1,2}$/;
    if (!startParams || !startParams.match(regex) || !locationId) {
      setError(true);
      return;
    }

    const startLocationParts = startParams.split(',', 3).map(n => parseInt(n));
    wizardContext.startLocation = {
      center: [startLocationParts[0], startLocationParts[1]],
      zoom: startLocationParts[2] + 2,
    }

    if (wizardContext.startLocation.zoom < 9) {
      wizardContext.startLocation.zoom = 9;
    }
    else if (wizardContext.startLocation.zoom> 13) {
      wizardContext.startLocation.zoom = 13;
    }

    const url = `/geolocate/lookup/${locationId}`;
    api.get<LookupResult>(url)
      .then(response => {
        try {
          wizardContext.searchLocation = new SearchLocation(response.data.response.docs[0]);
          navigate('/step2', {replace: false});
        } catch (error) {
          setError(true);
        }
      });
    // eslint-disable-next-line
  }, [startParams, locationId]);

  return (
    <div className={classes.stepContainer}>
      {!error ? <CircularProgress size={128}/> : <p>Ongeldige start parameters.</p>}
    </div>
  );
}

export default Start;
