import React from "react";
import {Link} from "react-router-dom";

const AlgemeneVoorwaardenText = () => {
  return (
    <React.Fragment>
      <p><i>Versie: 11 februari 2022</i></p>

      <p>iDelft BV verleent u hierbij toegang tot deze Website en verstrekt luchtfotomateriaal zoals deze ter
        beschikking wordt gesteld door de Nederlandse overheid.</p>

      <h2>Opdrachtverstrekking</h2>
      <p>Via deze Website geeft u iDelft BV opdracht om een product gereed te maken en aan u te verstrekken. U dient
        wel vooraf in te stemmen met onderstaande Voorwaarden.</p>

      <h2>Beperkte aansprakelijkheid</h2>
      <p>De op de Website aangeboden materialen worden aangeboden zonder enige vorm van garantie of aanspraak op
        juistheid. Deze materialen kunnen op elk moment wijzigen zonder voorafgaande mededeling van iDelft BV.</p>

      <h2>Privacyreglement</h2>
      <p>Op alle uitgevoerde opdrachten is het <Link to="/privacy">Privacyreglement</Link> van toepassing. Door in te stemmen met deze
        voorwaarden stemt u ook expliciet in met dit reglement.</p>

      <h2>Auteursrechten</h2>
      <p>Alle intellectuele eigendomsrechten betreffende deze Website met uitzondering van de luchtfoto's berusten
        bij iDelft BV. Kopiëren, hergebruik of reverse engineering van deze Website is niet toegestaan.</p>

      <p>De eigendomsrechten betreffende de luchtfoto's op deze Website berusten bij de Nederlandse Overheid. Aan u
        wordt een gebruikerslicentie verstrekt. Hergebruik van fotomateriaal is dus toegestaan, mits u niet het
        intellectueel eigendomsrecht claimt.</p>

      <p>Het is niet toegestaan om webpagina's of individuele webpagina elementen (zoals logo's, teksten of foto's)
        van de Website in een frameset of via een inline link in een andere webpagina te verwerken, indien daarbij
        verwarring kan ontstaan over de afkomst van het materiaal.</p>

      <h2>Overig</h2>
      <p>iDelft BV behoudt zich het recht voor om op elk moment de inhoud van deze Website aan te passen of
        onderdelen te verwijderen zonder u daarover voorafgaand te informeren. Deze Voorwaarden kunnen van tijd tot
        tijd wijzigen. Wijzigingen zijn geldig voor alle nieuw uit te voeren opdrachten. Dergelijke wijzigingen
        worden via deze pagina gecommuniceerd.</p>

      <p>
        Geregistreerde handelsnaam: iDelft BV<br />
        Adres: Koornmarkt 70, 2611 EJ Delft<br />
        KvK nummer: 27168688<br />
        BTW nummer: NL810606264B01<br />
        E-mail adres: <a href="mailto:bestel@bestel3d.nl">bestel@bestel3d.nl</a>
      </p>
    </React.Fragment>
  );
};

export default AlgemeneVoorwaardenText;
