import React from "react";
import classes from "./BestelInfoPagina.module.scss";
import highResImageKlein from "./voorbeeld-binnenhof-hoog-cropped.jpg";
import highResImageGroot from "./voorbeeld-binnenhof-hoog.jpg";
import makeStyles from "@mui/styles/makeStyles";
import {Link, useNavigate} from "react-router-dom";
import {Button, Grid} from "@mui/material";
import {NavigateNext} from "@mui/icons-material";

const useStyles = makeStyles((theme) => ({
  stepContainer: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  }
}));
const BestelInfoPagina = () => {

  const navigate = useNavigate();

  const containerClasses = useStyles();
  return (
    <div className={containerClasses.stepContainer}>
      <h1>Hoge resolutie Luchtfoto's kopen</h1>
      <p>Op deze website zijn scherpe actuele luchtfoto's te koop voor elk deel van Nederland, vanaf €5,- excl. BTW.
        De luchtfoto's hebben een hoge resolutie (kwaliteit) en een brede toepasbaarheid.</p>

      <p>Hieronder kunt u een voorbeeld bekijken van een luchtfoto. Klik op de luchtfoto om het volledige formaat te bekijken, of <Link to="/step1">bestel direct een luchtfoto</Link>.</p>

      <Grid container spacing={2} justifyContent="space-between">
        <Grid item md={4} flexGrow={1}>
            <table className={classes.comparison}>
              <caption>Details luchtfoto</caption>
              <tbody>
              <tr>
                <th>Resolutie</th>
                <td>Hoog (8cm/pixel)</td>
              </tr>
              <tr>
                <th>Watermerk</th>
                <td>Nee</td>
              </tr>
              <tr>
                <th>Georeferentie</th>
                <td>Ja (ook geschikt voor GIS systemen)</td>
              </tr>
              <tr>
                <th>Toepassing</th>
                <td>Commercieel, Brochures, Rapporten, Advertenties, Dakinspecties, Kadastrale&nbsp;geschillen</td>
              </tr>
              </tbody>
            </table>

          <div className={classes.orderButton}>

            <Button
              variant="contained"
              color="primary"
              endIcon={<NavigateNext />}
              sx={{fontSize: "1.2em"}}
              onClick={() => {navigate('/step1');}}
            >
              Bestel een luchtfoto
            </Button>

          </div>
        </Grid>
        <Grid item md={8} flexGrow={1} flexShrink={1}>
          <a href={highResImageGroot} title="Bekijk het voorbeeld in volledig formaat" target="_blank" rel="noopener noreferrer">
            <img src={highResImageKlein} alt="Voorbeeld van luchtfoto die besteld is op BestelLuchtfoto.nl" className={classes.previewImage} />
          </a>
        </Grid>
      </Grid>
    </div>
  );
}

export default BestelInfoPagina;
