import React, {useContext, useEffect} from "react";
import {Button, CircularProgress} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import Checkmark from "../../components/AnimatedIcons/Checkmark/Checkmark";
import {Link, useParams} from "react-router-dom";
import api from "../../store/api";
import Cross from "../../components/AnimatedIcons/Cross/Cross";
import WizardContext from "../../store/wizard-context";

const useStyles = makeStyles((theme) => ({
  stepContainer: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    textAlign: "center"
  }
}));

interface IProps {
  success?: boolean
}

const OrderStatus: React.FC<IProps> = (props) => {
  const {orderLongId} = useParams();
  const [success, setSuccess] = React.useState(props.success);
  const [loading, setLoading] = React.useState(!props.success);
  const [checkoutUrl, setCheckoutUrl] = React.useState<string>();
  const wizardContext = useContext(WizardContext);

  const classes = useStyles();

  useEffect(function () {
    if (!success) {
      api.get(`/order/${orderLongId}/check_payment`)
        .then(response => {
          setLoading(false);
          if (response.data.success) {
            setSuccess(true);
            return;
          }

          if (response.data.checkout_url) {
            setCheckoutUrl(response.data.checkout_url);
            return;
          }

          setSuccess(false);
        })
        .catch(_ => {
          setLoading(false);
          setSuccess(false);
        })
    }
    else {
      wizardContext.startLocation = undefined;
      wizardContext.searchLocation = undefined;
      wizardContext.boundingBox = undefined;
      wizardContext.zoom = undefined;
    }
  }, [setSuccess, orderLongId, success]);

  if (success) {
    return (
      <div className={classes.stepContainer}>
        <Checkmark/>

        <h2>Bedankt voor uw bestelling</h2>
        <p>Uw luchtfoto wordt nu klaargemaakt en z.s.m. naar u gemaild. Dit kan enkele minuten duren.</p>

        <Button
          component={Link}
          to="/step1"
          variant="contained"
          color="primary"
        >
          Terug naar begin
        </Button>
      </div>
    );
  }

  if (loading) {
    return (
      <div className={classes.stepContainer}>
        <CircularProgress size={128}/>
        <p>Bezig met het verwerken van uw order. Een moment geduld a.u.b.</p>
      </div>
    );
  }

  if (checkoutUrl) {
    return (
      <div className={classes.stepContainer}>
        <CircularProgress size={128}/>
        <p>We wachten nog op de verweking van uw betaling.</p>
        <a href={checkoutUrl}>Start uw betaling opnieuw</a>
      </div>
    );
  }

  return (
    <div className={classes.stepContainer}>
      <Cross/>
      <p>Sorry, er is iets mis gegaan.</p>
    </div>
  );
}

export default OrderStatus;
