import IPremiumUser from "../types/IPremiumUser";

interface imageDetailsInterface {
  widthInPx: number,
  widthInMeters: number,
  resolution: number,
  isFullRes: boolean,
  price: number,
  priceStr: string,
}

const calculateImageDetails = (bounding_box: [number, number, number, number], premiumUser?: IPremiumUser): imageDetailsInterface => {
  const minWidthM = 210;
  const minimumPriceInEuro = 5;
  const fullResolution = 8;
  const maxWidthPx = premiumUser?.highRes3x3 ? 12000 : 8000;
  const maxWidthM = maxWidthPx * fullResolution / 100;
  const pricePerM = minimumPriceInEuro / minWidthM;
  let resolution = fullResolution;

  const widthInMeters = Math.max(premiumUser?.highRes3x3 ? 900 : bounding_box[2] - bounding_box[0], minWidthM);

  let widthInPx = widthInMeters * 100 / fullResolution;
  let price = pricePerM * widthInMeters

  const isFullRes = premiumUser?.highRes3x3 || widthInPx <= maxWidthPx;
  if (!isFullRes) {
    const scale = Math.max(widthInPx / maxWidthPx, 1);
    resolution = fullResolution * scale;
    price = pricePerM * maxWidthM;

    const extraMeters = widthInMeters - maxWidthM;
    const extraPrice = (pricePerM / scale) * extraMeters;
    price += extraPrice;
  }

  if (premiumUser?.discount) {
    price *= (100 - premiumUser.discount) / 100;
  }

  let priceStr = `€${price.toFixed(2).replace('.', ',').replace(',00', ',-')} (excl. BTW)`;
  if (premiumUser?.premium) {
    price = 0;
    priceStr = "Reeds betaald";
  }
  else if(price === 0) {
    priceStr = "Gratis";
  }

  return {
    widthInPx: Math.min(widthInPx, maxWidthPx),
    widthInMeters,
    resolution,
    isFullRes,
    price,
    priceStr,
  }
}

export {
  calculateImageDetails
};
export type {
  imageDetailsInterface
};
