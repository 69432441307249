import React, {useEffect, useState} from "react";
import makeStyles from '@mui/styles/makeStyles';
import {Card, CardContent, CardMedia, Grid, Skeleton, Typography} from "@mui/material";
import api from "../../store/api";

const useStyles = makeStyles(theme => ({
  container: {
    margin: "0 " + theme.spacing(2),
  },
  title: {
    fontSize: '2rem',
  },
  grid: {
    width: "calc(100% + 2em)",
    gap: theme.spacing(2),
    overflowX: 'scroll',
    flexWrap: 'nowrap',
    padding: '0 1em 1em',
    margin: '0 -1em',
    boxSizing: 'border-box',
    [theme.breakpoints.up('md')]: {
      width: '100%',
      padding: '0',
      margin: 0,
      justifyContent: 'space-between',
      overflow: 'visible'
    },
  },
  gridItem: {
    minWidth: '200px',
  },
  gridScroll: {
    [theme.breakpoints.down('md')]: {
      padding: '0 1em',
      margin: '0 -1em',
      position: 'relative',
      '&::before, &::after': {
        content: '""',
        position: 'absolute',
        zIndex: 1,
        top: 0,
        bottom: 0,
        pointerEvents: 'none',
        width: '1em',
      },
      '&::before': {
        left: 0,
        backgroundImage: 'linear-gradient(to left, rgba(255,255,255,0), #FFF 85%)',
      },
      '&::after': {
        right: 0,
        backgroundImage: 'linear-gradient(to right, rgba(255,255,255,0), #FFF 85%)',
      }
    }
  }
}));

interface IRecentLufo {
  name: string,
  image: string,
  date: string
}

const RecentLufos: React.FC = () => {
  const classes = useStyles();

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [lufos, setLufos] = useState<IRecentLufo[]>([
    {name: "", image: "", date: ""},
    {name: "", image: "", date: ""},
    {name: "", image: "", date: ""},
  ]);

  useEffect(() => {
    api.get<IRecentLufo[]>('/recent_lufos')
      .then(response => {
        if (Array.isArray(response.data)) {
          setLufos(response.data);
          setLoading(false);
        }
      })
      .catch(() => {
        setError(true);
      });
  }, [setLufos]);


  if (error) {
    return null;
  }
  return (
    <div className={classes.container}>
      <h2 className={classes.title}>Recente luchtfoto's...</h2>

      <div className={classes.gridScroll}>
        <Grid container className={classes.grid}>
          {lufos.map((lufo, index) => (
            <Grid item className={classes.gridItem} key={index} flex={1}>
              <Card>
                {loading ? (
                  <CardMedia>
                    <Skeleton variant="rectangular" height={150}/>
                  </CardMedia>
                ) : (
                  <CardMedia
                    height={150}
                    component="img"
                    image={lufo.image}/>
                )}
                <CardContent>
                  <Typography variant="h6" component="div">
                    {loading ? <Skeleton/> : lufo.name}
                  </Typography>
                  <Typography variant="caption" color="text.secondary">
                    {loading ? <Skeleton/> : lufo.date}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </div>
    </div>
  );
}

export default RecentLufos;
