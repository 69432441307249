/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useContext, useEffect, useRef} from "react";
import makeStyles from "@mui/styles/makeStyles";
import {Button, Checkbox, CircularProgress, Container, FormControlLabel, Grid, TextField} from "@mui/material";
import EulaAcceptDialog from "../../components/EulaAcceptDialog/EulaAcceptDialog";
import {SideBySideMagnifier} from "react-image-magnifiers";
import {Send} from "@mui/icons-material";
import WizardContext from "../../store/wizard-context";
import {Link, useNavigate} from "react-router-dom";
import useSimpleInput from "../../hooks/use-simple-input";
import api, {API_URL} from "../../store/api";
import AlgemeneVoorwaardenText from "../../components/AlgemeneVoorwaardenText/AlgemeneVoorwaardenText";
import {calculateImageDetails} from "../../helpers/lufo-helper";
import IPremiumUser from "../../types/IPremiumUser";
import {useGA4React} from "ga-4-react";

const useStyles = makeStyles((theme) => ({
  stepContainer: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
  },

  previewWrapperSquare: {
    width: '100%',
    paddingBottom: '100%',
    border: '1px solid #EEE',
    position: 'relative',
  },

  orderPreview: {
    position: 'absolute',
    inset: '0',
    '& > div > img': {
      objectFit: 'contain',
      marginBottom: '2em',
    }
  },

  orderDetails: {
    width: '100%',
    lineHeight: '2em',
    '& th': {
      textAlign: 'left'
    }
  }
}));

interface IPostOrderResponse {
  success: boolean,
  redirect_url: string,
}

const Step4: React.FC = () => {
  const wizardContext = useContext(WizardContext);
  const navigate = useNavigate();
  const ga = useGA4React();

  const classes = useStyles();
  const [algemeneVoorwaardenDialogOpen, setalgemeneVoorwaardenDialogOpen] = React.useState(false);
  const [premiumUser, setPremiumUser] = React.useState<IPremiumUser|undefined>(undefined);

  const algemeneVoorwaardenLinkClickHander = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    setalgemeneVoorwaardenDialogOpen(true);
  }

  const algemeneVoorwaardenDialogCloseHandler = () => {
    setalgemeneVoorwaardenDialogOpen(false);
  }

  const [privacyDialogOpen, setprivacyDialogOpen] = React.useState(false);

  const privacyLinkClickHander = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    setprivacyDialogOpen(true);
  }

  const privacyDialogCloseHandler = () => {
    setprivacyDialogOpen(false);
  }

  const [eulaChecked, setEulaChecked] = React.useState(false);
  const eulaCheckboxRef = React.useRef<HTMLInputElement | null>(null);

  const eulaDialogAcceptHandler = () => {
    setEulaChecked(true);
    setalgemeneVoorwaardenDialogOpen(false);
    setprivacyDialogOpen(false);
  }

  const eulaCheckboxChangeHanlder = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEulaChecked(event.target.checked || false);
  }

  const [loading, setLoading] = React.useState(false);

  const {
    value: enteredOrganization,
    valueChangeHandler: organizationChangeHandler,
  } = useSimpleInput(_ => true);

  const {
    value: enteredFirstName,
    isValid: firstNameIsValid,
    hasError: firstNameHasError,
    valueChangeHandler: firstNameChaneHandler,
    inputBlurHandler: firstNameBlurHandler
  } = useSimpleInput(value => value.trim() !== '')

  const {
    value: enteredInterfix,
    valueChangeHandler: interfixChangeHandler,
  } = useSimpleInput(_ => true);

  const {
    value: enteredLastName,
    isValid: lastNameIsValid,
    hasError: lastNameHasError,
    valueChangeHandler: lastNameChangeHandler,
    inputBlurHandler: lastNameBlurHandler
  } = useSimpleInput(value => value.trim() !== '')

  const {
    value: enteredEmail,
    isValid: emailIsValid,
    hasError: emailHasError,
    valueChangeHandler: emailChangeHandler,
    inputBlurHandler: emailBlurHandler
  } = useSimpleInput(value => {
    return !!value.toLowerCase().match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
  });

  const {
    value: enteredAddress,
    isValid: addressIsValid,
    hasError: addressHasError,
    valueChangeHandler: addressChangeHandler,
    inputBlurHandler: addressBlurHandler
  } = useSimpleInput(value => value.trim() !== '');

  const {
    value: enteredZipCode,
    isValid: zipCodeIsValid,
    hasError: zipCodeHasError,
    valueChangeHandler: zipCodeChangeHandler,
    inputBlurHandler: zipCodeValidateBlurHandler,
    setEnteredValue: setZipCodeValue,
  } = useSimpleInput(value => !!value.match(/^\d{4} *[a-zA-Z]{2}$/));

  const zipCodeBlurHandler = (e: React.FocusEvent<HTMLInputElement>) => {
    zipCodeValidateBlurHandler();
    if (zipCodeIsValid) {
      let value = e.target.value;
      value = value.replace(/^(\d{4}) *([a-zA-Z]{2})$/, '$1 $2').toUpperCase();
      setZipCodeValue(value);
    }
  }

  const {
    value: enteredCity,
    isValid: cityIsValid,
    hasError: cityHasError,
    valueChangeHandler: cityChangeHandler,
    inputBlurHandler: cityBlurHandler,
  } = useSimpleInput(value => value.trim() !== '');

  const {
    value: enteredReference,
    valueChangeHandler: referenceChangeHandler,
  } = useSimpleInput(_ => true);

  const formIsValid = eulaChecked && firstNameIsValid && lastNameIsValid && emailIsValid && addressIsValid && zipCodeIsValid && cityIsValid;

  const imageCalculations = calculateImageDetails(wizardContext.boundingBox!, premiumUser);

  const onSubmitHandler = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (!eulaChecked) {
      return;
    }

    setLoading(true);

    if (formIsValid) {
      api.post<IPostOrderResponse>('/orders', {
        'order': {
          'search_location': wizardContext.searchLocation?.weergavenaam,
          'mode': wizardContext.downloadMode === 'full' ? 'paid' : 'free',
          'bounding_box': wizardContext.boundingBox!.join(','),
          'reference': enteredReference,
        },
        'details': {
          'organization': enteredOrganization,
          'email': enteredEmail,
          'first_name': enteredFirstName,
          'interfix': enteredInterfix,
          'last_name': enteredLastName,
          'address': enteredAddress,
          'zipcode': enteredZipCode,
          'city': enteredCity,
        }
      })
        .catch(_ => {
          setLoading(false);
          window.alert('Er is iets mis gegaan. Probeer het later opnieuw.');
          return Promise.reject();
        })
        .then(response => {
          setLoading(false);
          if (response.data.success && response.data.redirect_url !== '') {
            wizardContext.searchLocation = undefined;
            wizardContext.boundingBox = undefined;

            if (ga) {
              ga.gtag('event', 'purchase', {
                value: imageCalculations.price,
                currency: 'EUR',
                items: [
                  {
                    item_name: 'Luchtfoto',
                    item_variant: `${Math.round(imageCalculations.widthInMeters)} meter. Resolutie: ${imageCalculations.resolution.toFixed(2)}cm`,
                  },
                ],
              });
            }

            if (response.data.redirect_url.startsWith('/')) {
              navigate(response.data.redirect_url)
            }
            else {
              window.location.href = response.data.redirect_url;
            }
            return;
          }
          window.alert('Er is iets mis gegaan. Probeer het later opnieuw.');
        });
    }
  }

  const emailRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    setPremiumUser(undefined);
    if (!emailIsValid || emailHasError || enteredEmail === '') {
      return;
    }

    const timer = setTimeout(() => {
      if (emailRef.current!.value !== enteredEmail) {
        return;
      }
      const url = `/premium/check/${encodeURI(enteredEmail)}`;
      api.get<IPremiumUser>(url)
        .then(response => {
          setPremiumUser(response.data.premium ? response.data : undefined);
        });
    }, 500);

    return () => {
      clearTimeout(timer);
    }
  }, [enteredEmail, emailIsValid, emailHasError]);

  const previewUrl = API_URL + '/lufo_example/' + wizardContext.boundingBox!.join(',') + '/' + (wizardContext.downloadMode === "full" ? '1' : '0') + '?example=1&_token=' + localStorage.getItem('token');

  return (
    <Container fixed className={classes.stepContainer}>
      <Grid container spacing={2} justifyContent="space-between">
        <Grid item md={4}>
          Voorbeeld van uw luchtfoto: <br />
          <div className={classes.previewWrapperSquare}>
          <SideBySideMagnifier
            imageSrc={previewUrl}
            alwaysInPlace={true}
            className={classes.orderPreview}
          />
          </div>
        </Grid>

        <Grid item md={8}>

          <h2>Uw luchtfoto</h2>

          <div>
            <table className={classes.orderDetails}>
              <tbody>
              <tr>
                <th>Oppervlakte:</th>
                <td>{Math.round(imageCalculations.widthInMeters)} x {Math.round(imageCalculations.widthInMeters)} meter</td>
              </tr>
              <tr>
                <th>Formaat luchtfoto:</th>
                <td>{Math.round(imageCalculations.widthInPx)} x {Math.round(imageCalculations.widthInPx)} px</td>
              </tr>
              <tr>
                <th>Resolutie:</th>
                <td>1px = { Math.round(imageCalculations.resolution * 10) / 10 }cm</td>
              </tr>
              <tr>
                <th>Prijs:</th>
                <td>{imageCalculations.priceStr}</td>
              </tr>
              <tr>
                <th>Referentie:</th>
                <td><TextField
                  placeholder="Type uw referentie"
                  onChange={referenceChangeHandler}
                  fullWidth/></td>
              </tr>
              </tbody>
            </table>
          </div>

          <h2>Uw gegevens</h2>
          <form onSubmit={onSubmitHandler} autoComplete="on">
            <div>
              <TextField
                type="text"
                label="Bedrijf"
                onChange={organizationChangeHandler}
                autoComplete="organization"
                fullWidth/>
            </div>
            <div>
              <Grid container spacing={2}>
                <Grid item sm={3}>
                  <TextField
                    label="Voornaam"
                    onChange={firstNameChaneHandler}
                    onBlur={firstNameBlurHandler}
                    error={firstNameHasError}
                    autoComplete="givenName"
                    required
                    fullWidth/>
                </Grid>
                <Grid item sm={3}>
                  <TextField
                    label="Tussenvoegsel"
                    onChange={interfixChangeHandler}
                    fullWidth/>
                </Grid>
                <Grid item sm={6}>
                  <TextField
                    label="Achternaam"
                    onChange={lastNameChangeHandler}
                    onBlur={lastNameBlurHandler}
                    error={lastNameHasError}
                    autoComplete="family-name"
                    required
                    fullWidth/>
                </Grid>
              </Grid>
            </div>
            <div>
              <TextField
                type="email"
                label="E-mailadres"
                inputRef={emailRef}
                onChange={emailChangeHandler}
                onBlur={emailBlurHandler}
                error={emailHasError}
                autoComplete="email"
                required
                fullWidth/>
            </div>
            <div>
              <TextField
                label="Adres"
                onChange={addressChangeHandler}
                onBlur={addressBlurHandler}
                error={addressHasError}
                autoComplete="street-address"
                required
                fullWidth/>
            </div>
            <div>
              <Grid container spacing={2}>
                <Grid item sm={4}>
                  <TextField
                    label="Postcode"
                    onChange={zipCodeChangeHandler}
                    onBlur={zipCodeBlurHandler}
                    error={zipCodeHasError}
                    autoComplete="postal-code"
                    value={enteredZipCode}
                    required
                    fullWidth/>
                </Grid>
                <Grid item sm={8}>
                  <TextField
                    label="Plaats"
                    onChange={cityChangeHandler}
                    onBlur={cityBlurHandler}
                    error={cityHasError}
                    autoComplete="address-level2"
                    required
                    fullWidth/>
                </Grid>
              </Grid>
            </div>

            <div>
              <FormControlLabel
                control={<Checkbox checked={eulaChecked}
                                   inputRef={eulaCheckboxRef}
                                   name="eula"
                                   onChange={eulaCheckboxChangeHanlder}/>}
                label={
                  <span>Ik ga akkoord met de <Link to="/algemenevoorwaarden"
                                                   onClick={algemeneVoorwaardenLinkClickHander} target="_blank">algemene voorwaarden</Link> en het <Link
                    to="/privacy" onClick={privacyLinkClickHander} target="_blank">privacy statement</Link>.</span>}
              />
            </div>

            <div>
              <Button
                variant="contained"
                color="primary"
                endIcon={
                  loading ? <CircularProgress size={20}/> : <Send/>
                }
                type="submit"
                disabled={loading || !formIsValid}
              >
                Bestellen
              </Button>
            </div>

          </form>
        </Grid>
      </Grid>

      <EulaAcceptDialog title="Algemene voorwaarden" open={algemeneVoorwaardenDialogOpen}
                        onClose={algemeneVoorwaardenDialogCloseHandler}
                        onAccept={eulaDialogAcceptHandler}>
        <AlgemeneVoorwaardenText/>
      </EulaAcceptDialog>

      <EulaAcceptDialog title="Privacy statement" open={privacyDialogOpen} onClose={privacyDialogCloseHandler}
                        onAccept={eulaDialogAcceptHandler}>
        <AlgemeneVoorwaardenText/>
      </EulaAcceptDialog>

    </Container>
  );
}

export default Step4;
