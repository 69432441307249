import React from 'react';
import {SearchLocation, StartLocation} from "../types/PdokSearchResults";

interface wizardStateInterface {
  searchLocation?: SearchLocation,
  boundingBox?: [number, number, number, number],
  zoom?: number,
  downloadMode: "free" | "full",
  startLocation?: StartLocation
}

export const defaultWizardContext: wizardStateInterface = {
  searchLocation: undefined,
  boundingBox: undefined,
  zoom: undefined,
  downloadMode: "free",
  startLocation: undefined,
};

const WizardContext = React.createContext<wizardStateInterface>(defaultWizardContext);
export default WizardContext;
