import React, {useContext} from "react";
import makeStyles from '@mui/styles/makeStyles';
import "../../scss/_base.scss"
import {SearchLocation} from "../../types/PdokSearchResults";
import WizardContext from "../../store/wizard-context";
import {Link, useNavigate} from "react-router-dom";
import {is_gratis} from "../../store/gratis-of-bestel";
import background from "./background.jpg";
import SearchForm from "./SearchForm";
import RecentLufos from "./RecentLufos";

const useStyles = makeStyles((theme) => ({
  backgroundWrapper: {
    position: 'relative',
    padding: '1rem 0',
    margin: '0 -1rem 1rem',
  },

  background: {
    position: 'absolute',
    inset: 0,
    background: 'url(' + background + ') center center',
    backgroundSize: 'cover',
  },

  box: {
    background: 'white',
    margin: '0 auto',
    padding: '2rem',
    position: 'relative',

    [theme.breakpoints.up('sm')]: {
      width: '70%',
    }
  },

  stepContainer: {
    paddingBottom: theme.spacing(3),
  },
}));

const Step1: React.FC = () => {
  const wizardContext = useContext(WizardContext);
  const navigate = useNavigate();

  const classes = useStyles();

  const onSearchSubmit = (location: SearchLocation) => {
    wizardContext.searchLocation = location;
    navigate('/step2');
  }

  let intro;
  if (is_gratis) {
    intro = (
      <React.Fragment>
        <h1>Welkom bij Gratis-Luchtfoto.nl</h1>
        <p>Via deze website kunt kunt u Nederlandse luchtfoto's bestellen en downloaden. Gratis luchtfoto's worden ter
          beschikking gesteld door de Nederlandse overheid en zijn prima geschikt om een eerste indruk te krijgen van
          een bepaald gebied. De beelden hebben een lage resolutie en de detaillering is beperkt.</p>

        <p>Het is ook mogelijk om hoge resolutie luchtfoto's te kopen voor elk deel van Nederland vanaf € 5,00 excl.
          BTW. Deze luchtfoto's hebben een 40 maal hogere resolutie (kwaliteit) dan de gratis variant. De beelden zijn
          daardoor breder toepasbaar.</p>

        <p>Om gebruik te maken van deze service dient u in te stemmen met de <Link to="/algemenevoorwaarden">Algemene
          Voorwaarden</Link> en het <Link to="/privacy">Privacy Reglement</Link>.</p>
      </React.Fragment>
    );
  } else {
    intro = (
      <React.Fragment>
        <h1>Bestel uw eigen luchtfoto</h1>
        {/*<p>Via deze website kunt kunt u gedetailleerde luchtfoto's bestellen en downloaden. Het hele Nederlandse*/}
        {/*  grondgebied plus de grensstreek is beschikbaar. Op de hoge resolutie luchtfoto's zijn kleine objecten te*/}
        {/*  onderscheiden. U kunt al een hoge-resolutie luchtfoto kopen vanaf € 5,00 excl. BTW per foto en u kunt betalen*/}
        {/*  met IDEAL. Voorafgaand aan de betaling ziet u een voorbeeld van uw luchtfoto.*/}
        {/*</p>*/}
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      <div className={classes.stepContainer}>
        <div className={classes.backgroundWrapper}>
          <div className={classes.background}></div>

          <div className={classes.box}>
            {intro}
            <SearchForm onSubmit={onSearchSubmit} />
          </div>
        </div>

        <RecentLufos />
      </div>
    </React.Fragment>
  );
};

export default React.memo(Step1);
