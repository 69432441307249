import React from "react";

const PrivacyStatementText = () => {
  return (
    <React.Fragment>
      <p><i>Versie: 11 februari 2022</i></p>

      <h2>Algemeen</h2>
      <p>Wij respecteren de privacy van bezoekers van deze website en dragen er zorg voor dat de gegevens die u ons
        verschaft vertrouwelijk worden behandeld. Verwerking van de gegevens gebeurt op een wijze, die in
        overeenstemming is met de eisen die de Algemene Verordening Gegevensbescherming (AVG) stelt.</p>

      <h2>Doeleinden van de gegevensverwerking</h2>
      <p>Uw gegevens worden door ons, iDelft BV, verwerkt voor het aangaan en uitvoeren van overeenkomsten ter zake van
        digitale luchtfoto's en het beheren van de daaruit voortvloeiende relaties.</p>

      <p>Als u een product afneemt via deze website dan wordt uw e-mailadres bewaard, zodat we uw opdracht kunnen
        uitvoeren. Er is daarbij sprake van een relatie en een overeenkomst tussen de opdrachtgever en de opdrachtnemer.
        Na uw toestemming kunnen we het e-mailadres gebruiken om de opdrachtgever te informeren over nieuwe producten of
        functionaliteiten van de website. Dit zullen we nooit vaker dan éénmaal per kwartaal doen. Ook zullen we
        e-mailadressen opslaan om vragen van opdrachtgevers te kunnen beantwoorden. Onder geen beding verkoopt iDelft BV
        uw gegevens aan derden. Ook stelt iDelft BV normaliter geen gegevens ter beschikking van derden.</p>

      <p>Gegevens kunnen echter wel worden gebruikt voor opsporing, als via de site strafbare feiten worden gepleegd of
        strafbare uitlatingen worden gedaan (en verdere uitzonderingen zoals genoemd in artikel 43 Wet bescherming
        persoonsgegevens).</p>

      <h2>Klikgedrag en bezoekgegevens</h2>
      <p>iDelft BV slaat de luchtfotozoekopdracht op. Dit betekent concreet dat we een luchtfoto uitleveren op basis van
        een sessie ID, IP adres, het gekozen gebied, het tijdstip en het e-mailadres. We slaan deze gegevens op om de
        werking van de website te kunnen garanderen en om historische zoekopdrachten te kunnen weergeven.</p>

      <p>Op de website worden algemene bezoekgegevens bijgehouden. Mogelijk misbruik van de website wordt tegengegaan.
        In dit kader kan met name het IP-adres en het tijdstip van opvraging worden gebruikt. Tevens optimaliseren wij
        de werking van de website door statistische analyses uit te voeren van bezoek- en klikgedrag op de website. Wij
        verkopen deze gegevens niet aan derden.</p>

      <h2>Cookiepolicy - Gebruik van cookies</h2>
      <p>Wij maken op deze website gebruik van cookies. Een cookie is een eenvoudig klein bestandje dat met pagina's van
        deze website wordt meegestuurd en door uw browser op uw harde schrijf van uw computer wordt opgeslagen. Wij
        gebruiken cookies om uw instellingen en ordergegevens te onthouden. Dit wordt ook wel een technisch cookie
        genoemd. Voor verdere informatie over cookies en het uitzetten van cookies verwijzen we u naar onze speciale
        cookie pagina. Naast de technische cookies, maakt deze website ook gebruik van Google Analytics. Google
        Analytics is een systeem wat het gedrag van de bezoekers binnen de website bijhoudt aan de hand van Tracking
        Cookies. Met deze gegevens kunnen wij bijvoorbeeld eventuele knelpunten in de website analyseren en oplossen. We
        hebben Google Analytics zodanig geconfigureerd dat we voldoen aan Europese regelgeving.</p>

      <h2>Beveiliging</h2>
      <p>Gegevens worden door iDelft BV opgeslagen in een beveiligd Nederlands datacentrum. Een beveiligingsprotocol is
        van toepassing en alleen bevoegde medewerkers van iDelft BV hebben toegang tot de gegevens.</p>

      <h2>Inzage, correctie en verwijderen van gegevens</h2>
      <p>Indien u een relatie met ons bedrijf heeft, kunt u ons een e-mail sturen om uw gegevens in te zien, te
        verbeteren of geheel te laten verwijderen. Het is ook mogelijk om alleen het e-mailadres te laten verwijderen,
        zodat de bijbehorende luchtfoto nog wel beschikbaar blijft. Dit doet u door contact met ons op te nemen. U
        ontvangt dan geen e-mail correspondentie meer van ons.</p>

      <h2>Aanpassen privacyreglement</h2>
      <p>Wij behouden ons het recht voor dit privacyreglement aan te passen. Wijzigingen zullen op deze website worden
        gepubliceerd.</p>

    </React.Fragment>
  );
};

export default PrivacyStatementText;
